<template>
  <div
    class="v-header-button--barad-nimras v-mb-sm v-d-flex v-align-items-center v-pointer v-justify-content-center"
    :class="{
      'v-header-button--barad-nimras--hollow':
        appConfig.VueSettingsPreRun.SmallBasketShowMode === 'default'
    }"
    style="flex-direction: column"
    :data-test-id="smallBasketDataTestId"
    @click="emit('click')"
  >
    <div>
      <common-badge
        v-if="
          appConfig.VueSettingsPreRun.SmallBasketShowMode === 'default' ||
          appConfig.VueSettingsPreRun.SmallBasketShowMode === 'total-amount'
        "
        :invert-colors="
          appConfig.VueSettingsPreRun.SmallBasketShowMode !== 'default'
        "
      >
        <template #content>
          <icon-old-menu-basket class="v-header-button--barad-nimras__icon" />
        </template>
        <template #badge>
          {{ contentLength }}
        </template>
      </common-badge>
      <icon-old-menu-basket
        v-else
        class="v-header-button--barad-nimras__icon"
      />
    </div>
    <div
      v-if="
        appConfig.VueSettingsPreRun.SmallBasketShowMode === 'default' ||
        appConfig.VueSettingsPreRun.SmallBasketShowMode === 'money'
      "
      class="v-ml-xs v-w-100"
    >
      <common-currency
        v-if="toPayWithMoney === 0 && toPayWithPoints > 0"
        show-points
        :amount="toPayWithPoints"
      />
      <common-currency
        v-else
        :amount="toPayWithMoney"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import type { HeaderBasketButton } from '~types/props'

defineProps<HeaderBasketButton>()

const emit = defineEmits({
  click: null
})

const appConfig = useAppConfig()
</script>

<style lang="scss">
@use 'assets/variables';
@use 'assets/mixins';

.v-header-button--barad-nimras {
  @include mixins.trans;
  background-color: variables.$SmallBasketBackgroundColor;
  color: variables.$SmallBasketColor;

  border-radius: variables.$BorderRadiusButton;

  min-height: 42px;
  max-width: 100%;

  height: 80px;
  width: 80px;
  min-width: fit-content;
  padding: 0.5rem;

  .v-header-button--barad-nimras__icon {
    width: 40px;
    height: 40px;
  }

  margin: auto 0 auto auto;

  &:hover {
    background: variables.$SmallBasketBackgroundColorVariant;
    opacity: 0.75;
  }

  &--hollow {
    background-color: variables.$HeaderBackgroundColor;
    color: variables.$SmallBasketColor;
    border: 3px solid variables.$SmallBasketBackgroundColor;
    width: fit-content;

    &:hover {
      background: variables.$HeaderBackgroundColor;
      border-color: variables.$SmallBasketBackgroundColorVariant;
    }

    .v-badge-root > .v-badge {
      font-weight: 600;
      border-width: 3px;
    }

    .v-header-button--barad-nimras__icon {
      path {
        stroke: variables.$SmallBasketBackgroundColor;
      }
    }

    .v-currency-wrapper {
      color: variables.$SmallBasketBackgroundColor;
    }
  }

  &__left {
    border-right: 1.5px solid variables.$PrimaryColorOpaq50;
    padding-right: 1rem;
  }

  &__icon {
    width: 24px;
    height: 24px;
    fill: none;

    path {
      stroke: variables.$SmallBasketColor;
    }
  }
}
</style>
